/*!
 * Mobile menu.
 */
jQuery(document).ready(function($) {
  "use strict";

  $("button.navbar-toggle").click(function() {
    if ($(".navbar-collapse").hasClass("in")) {
      jQuery("button.navbar-toggle").removeClass("menu-open");
    } else {
      jQuery("button.navbar-toggle").addClass("menu-open");
    }
  });
});
