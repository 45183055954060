/*!
 * Main scripts.
 */
jQuery(document).ready(function($) {
  "use strict";

  var setEqualHeights = function(rows, cols) {
    var panels,
        panelHeight,
        maxHeight;
    $(rows).find(cols).css("height", "auto");
    $(rows).each(function() {
      maxHeight = 0;
      panels = $(this).find(cols);
      $(panels).each(function() {
        panelHeight = $(this).outerHeight();
        if (panelHeight > maxHeight) {
          maxHeight = panelHeight;
        }
      });

      if ($(window).width() > 747) {
        panels.css("height", maxHeight + "px");
      }
    });
  }

  var setEqualHeightsPromo = function(rows, cols) {
    var panels,
        imageHeight,
        contentHeight,
        maxImageHeight,
        maxContentHeight;

    $(rows).each(function() {
      maxImageHeight = 0;
      maxContentHeight = 0;
      panels = $(this).find(cols);

      // Need to reset the heights to the default.
      panels.find(".promo-pane--image").css("height", "auto");
      $(this).find(".promo-pane--image img").css({
        "padding-top": "0",
        "padding-bottom": "0"
      });
      $(panels).each(function() {
        imageHeight = $(this).find(".promo-pane--image").height();
        contentHeight = $(this).find(".promo-pane--content").outerHeight();
        if (imageHeight > maxImageHeight) {
          maxImageHeight = imageHeight;
        }
        if (contentHeight > maxContentHeight) {
          maxContentHeight = contentHeight;
        }
      });

      // Only apply settings if the screen is a certain size.
      if ($(window).width() > 747) {
        panels.find(".promo-pane--image").css("height", maxImageHeight + "px");
        panels.find(".promo-pane--content").css("height", maxContentHeight + "px");
        $(panels).each(function() {
          // Reset the heights before getting the real height.
          imageHeight = $(this).find(".promo-pane--image img").height();
          var paddingAmount = (maxImageHeight - imageHeight) / 2;
          $(this).find(".promo-pane--image img").css({
            "padding-top": paddingAmount,
            "padding-bottom": paddingAmount
          });
        });
      }
    });
  }

  $(window).load(function() {
    setEqualHeights('.panel-related-row', '.pane-bundle-rfl-paragraphs-pane');
    setEqualHeightsPromo('.panel-related-row', '.pane-bundle-rfl-promo-pane');

    $('.DateInput__input').attr('disabled', false);
  });

  $(window).resize(function() {
    setEqualHeights('.panel-related-row', '.pane-bundle-rfl-paragraphs-pane');
    setEqualHeightsPromo('.panel-related-row', '.pane-bundle-rfl-promo-pane');
  });
});
