/*!
 * Youtube additions.
 */

jQuery(document).ready(function($) {
  "use strict";

  var resizeVideo = function() {
    var windowWidth = $(window).width();
    var scaleFactor = 0.6;
    if (windowWidth && windowWidth <= 767) {
      var $youtubeEmbed = $('.youtube-embed');
      var videoWidth = $youtubeEmbed.width();
      $youtubeEmbed.css('height', videoWidth * scaleFactor + 'px');
    }
    else {
      $('.youtube-embed').each(function(index, element) {
        var $element = $(element);
        var newHeight = '';

        if ($element.closest('.col-sm-6').length || $element.closest('.col-sm-4').length) {
          newHeight = $element.width() * scaleFactor + 'px';
        }

        $element.css({
          height: newHeight
        });
      });
    }
  };

  $(window).load(resizeVideo);
  $(window).resize(resizeVideo);
});
